
$(function() {
    $(".df-multiple-checkbox-search").on("keyup keypress", function(e) {
        let keyCode = e.keyCode || e.which
        if (keyCode === 13) {
            e.preventDefault()
            return false
        }
        const filterValue = $(this).val().toLowerCase()

        const valuesList =$(this).parents("div, table").children(".df-values-list, tbody.df-values-list")

        $(valuesList).children("div, tr").each(function(index, node) {
            const self = $(node)
            const element = $(node).children("label, td")
            let txtValue = element.text()
            if (txtValue.toLowerCase().indexOf(filterValue) > -1) {
                self.show()
            } else {
                self.hide()
            }
        })
    })


    let $el1 = $("input[type=file].js-content-upload")
    // Файловые загрузчики
    $el1.each(function(index, elem) {

        $(elem).fileinput({
            browseClass: "btn btn-outline-primary",
            dropZoneEnabled: false,
            showCaption: false,
            showCancel: false,
            showUpload: false,
            showRemove: false,
            showUploadedThumbs: false,
            showPreview: false,
            uploadAsync: false,
            browseLabel: $(this).data("browse-label"),
            uploadUrl: $(this).data("upload-url"),
            autoReplace: true,
            mergeAjaxCallbacks: true,
            layoutTemplates: {
                progress: ""
            },
            ajaxSettings: {
                async: false,
                success: function(a, b) {
                    console.log('success', a, b)
                }
            }
        })

        $(elem).on("filebatchselected", function(event, files) {
            $(elem).fileinput("upload").fileinput("refresh")
            // $el1.fileinput("clear")

        })

        $(elem).on('filebatchuploadsuccess', function(event, data) {
            console.log('File pre upload triggered', data);
        });
        $(elem).on("filebatchuploadcomplete", function(event, data, previewId, index) {
            console.log(event, data, previewId, index)
            var form = data.form, files = data.files, extra = data.extra,
                response = data.response, reader = data.reader;
            console.log(form, files)
            console.log(response, extra, reader, data.jqXHR)
        })
    })
})
