import autosize from "autosize/dist/autosize";

$(function() {
    function switchOff(elem, btn) {
        if (!$(elem).hasClass("d-none"))
            $(elem).addClass("d-none")

        if ($(btn).children("i").hasClass("bi-x-lg")) {
            $(btn).children("i").removeClass("bi-x-lg")
            $(btn).children("i").addClass("bi-bank")
        }
    }
    function switchOn(elem, btn) {
        if ($(elem).addClass("d-none"))
            $(elem).removeClass("d-none")

        if ($(btn).children("i").hasClass("bi-bank")) {
            $(btn).children("i").removeClass("bi-bank")
            $(btn).children("i").addClass("bi-x-lg")
        }
    }

    const widgetContaier = $(".df-translatable-history")

    const optionsList = $(widgetContaier).children("ul.js-history-options-list")
    const inputField = $(widgetContaier).children(".js-translatable-input")

    $(document).on("mouseup", function(e) {
        if (!widgetContaier.is(e.target) && widgetContaier.has(e.target).length === 0)
            optionsList.each(function(idx, elem) { switchOff(elem, $(widgetContaier).children("button")) })
    })

    // Необходимо скрывать выпадающее меню при покидании фокуса / при фокусе на элемент ввода
    $(inputField).on("focus", function() {
        const selfOptionsList = $(this).siblings("ul.js-history-options-list")
        optionsList.each(function(idx, elem) {
            const selfBtn = $(this).siblings("button")
            if ($.inArray(elem, selfOptionsList) === -1)
                switchOff(elem, selfBtn)
        })
    })

    $(".js-history-choice").on("click", function(e) {
        e.preventDefault()
        const selfInput = $(this).parents(".df-translatable-history").children(".js-translatable-input")
        if (selfInput) {
            $(selfInput).val($(this).data("value"))
            $(selfInput).focus()
        }
    })

    // Обработка кнопки история
    $(widgetContaier).children("button.js-history-button").on("click", function(e) {
        const selfOptionsList = $(this).siblings("ul.js-history-options-list")
        const selfInput = $(this).siblings(".js-translatable-input")

        optionsList.each(function(idx, elem) {
            const selfBtn = $(elem).siblings("button")
            if ($.inArray(elem, selfOptionsList) === 0) {
                if ($(elem).hasClass("d-none")) {
                    switchOn(elem, selfBtn)
                    $(selfInput).focus()
                } else switchOff(elem, selfBtn)
            } else {
                switchOff(elem, selfBtn)
            }
        })
        return false
    })

    // Кнопочки для переводов
    $("input.js-translatable-input, textarea.js-translatable-input").on("keyup", function(e) {
        const rowContainer = $(this).parents("tr, div.js-df-translate-next-language-container")

        if ($(this).val()) {
            rowContainer.find("input[type=radio][value=2]").prop("checked", "checked")
        } else {
            rowContainer.find("input[type=radio][value=0]").prop("checked", "checked")
        }
    })

    $("button.js-df-translate-start-another").on("click", function(e) {
        const globalWidgetContainer = $(this).parents(".js-df-translatable-field")
        const languagesTable = $(globalWidgetContainer).find(".df-table-container")

        let inputs = $(languagesTable).find("input[data-locale].js-translatable-input")
        if (inputs.length === 0) {
            inputs = $(languagesTable).find("textarea[data-locale].js-translatable-input")
        }
        const sourceLocale = $(this).data("source-locale")

        const commonContainer = $(this).parents(".js-df-translate-google-container")
        const sourceContainer = $(commonContainer).children(".js-df-translate-next-language-container")

        const self = $(this)
        let targetObjects = []
        let targetLocales = []

        inputs.each(function(index, elem) {
            const s = $(elem)
            const tr = s.parents("tr")
            const state = parseInt($(tr).find("input[type=radio]:checked").val())
            const restricted = $(tr).find("input[type=checkbox]:checked").val()

            if (state in [0, 1] && !restricted) {
                $(elem).prop("disabled", true)
                const url = self.data("url")

                let inputFieldValue = $(sourceContainer).find("input[data-locale=" + sourceLocale + "].js-translatable-input").val()
                if (!inputFieldValue) {
                    inputFieldValue = $(sourceContainer).find("textarea[data-locale=" + sourceLocale + "].js-translatable-input").val()
                }

                const data = {
                    "source": sourceLocale,
                    "target": [s.data("locale")],
                    "value": inputFieldValue
                }

                $.ajax({
                    method: "POST",
                    url: url,
                    dataType: "json",
                    contentType: "application/json",
                    data: JSON.stringify(data),
                    success: function (data) {
                        if (data) {
                            $.each(data, function(locale, value) {
                                s.val(value)
                                autosize.update($("textarea.js-textarea-autosize"))
                                s.prop("disabled", false)
                                let state = $(tr).find("input[type=radio][value='1']")
                                state.attr("checked", "checked")
                                state.click()
                            })
                        }
                    },
                    error: function() {
                        s.prop("disabled", false)
                    }
                })
            }
        })
    })
    $("button.js-df-translate-start").on("click", function(e) {
        let self = $(this)
        const globalWidgetContainer = $(this).parents(".js-df-translatable-field")
        const sourceLocale = $(this).data("source-locale")
        const targetLocale = $(this).data("target-locale")

        const commonContainer = $(this).parents(".js-df-translate-google-container")
        const targetContainer = $(commonContainer).children(".js-df-translate-next-language-container")

        const state = parseInt($(targetContainer).find("input[type=radio]:checked").val())
        const restricted = $(targetContainer).find("input[type=checkbox]:checked").val()

        if (state in [0, 1] && !restricted) {
            const url = $(this).data("url")

            // console.log($(targetContainer), $(targetContainer).find("textarea.js-translatable-input"))

            let targetInput = $(targetContainer).find("input[data-locale=" + targetLocale + "].js-translatable-input")
            // console.log("first = ", targetInput)
            if (targetInput.length === 0) {
                targetInput = $(targetContainer).find("textarea[data-locale=" + targetLocale + "].js-translatable-input")
                // console.log("nextx = ", targetInput)
            }
            $(targetInput).prop("disabled", true)
            self.prop("disabled", true)

            let inputValue = $(commonContainer).find("input[data-locale=" + sourceLocale + "].js-translatable-input").val()
            if (!inputValue) {
                inputValue = $(commonContainer).find("textarea[data-locale=" + sourceLocale + "].js-translatable-input").val()
            }

            $.ajax({
                method: "POST",
                url: url,
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify({
                    "source": sourceLocale,
                    "target": [targetLocale],
                    "value": inputValue,
                }),
                success: function(data, textStatus, jqXHR) {
                    if (data) {
                        $.each(data, function(locale, value) {
                            $(targetInput).val(value)
                            autosize.update($("textarea.js-textarea-autosize"))
                            $(targetInput).prop("disabled", false)
                            let state = $(targetContainer).find("input[type=radio][value='1']")
                            state.attr("checked", "checked")
                            state.click()
                        })
                    }
                    self.prop("disabled", false)
                },
                error: function() {
                    $(targetInput).prop("disabled", false)
                    self.prop("disabled", false)
                }
            })
        } else {
            alert($(globalWidgetContainer).data("msg-start-condition"))
        }

        // console.log('source', sourceLocale, 'target', targetLocale, targetContainer, state)
    });
})
