import { TempusDominus } from "@eonasdan/tempus-dominus"

$(function() {
    const dSelectInput = $(".js-date-select-input")

    if (dSelectInput.length > 0) {
        dSelectInput.each(function(i, el){
            new TempusDominus(el, {
                allowInputToggle: true,
                useCurrent: false,
                localization: { locale: document.documentElement.lang, format: "dd.MM.yyyy" },
                display: {
                    icons: {
                        date: "bi-calendar",
                        today: "bi-calendar-check",
                        clear: "bi-trash"
                    },
                    components: { decades: false, clock: false },
                    buttons: { today: true, clear: true },
                    viewMode: "calendar"
                },
            });
        })
    }
})
