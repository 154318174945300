/* JS для работы со страницами ролей и прав доступа. */
// import UIkit from "uikit";

$(function() {
    $("#removeFeaturesGroupBtn").on("click", function(e) {
        if (!e.isDefaultPrevented()) {
            e.preventDefault()

            let rows = []
            let values = []


            $("input[type=checkbox].js-item-check:checked").each(function(index, element) {
                values.push($(element).val())
                rows.push($(element).parents("tr"))
            })

            $.ajax({
                method: "POST",
                url: $(this).attr("href"),
                data: {
                    ids: values
                },
                success(data, textStatus, jqXHR) {
                    $(rows).each(function(i, el) {
                        $(el).remove()
                    })
                    $(":checkbox[data-group-selector]").each(function(i, el) { $(el).prop("checked", false) })
                }
            })
        }
    })
})

// export function products_features(DFApp) {
//     // TODO: Globally?
//     $(document).ajaxError(function(event, jqXHR, settings, thrownError) {
//                     if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.message) {
//                         DFApp.notify(jqXHR.responseJSON.message, 'danger')
//                     }
//                 })
//
//     if (DFApp.body.hasClass("js-products js-products-manage-feature")) {
//         $('a.js-add-feature-to-product, a.js-remove-feature-from-product').on('click', function(e) {
//             e.preventDefault()
//             self = $(this)
//             $.ajax({
//                 url: self.attr("href"),
//                 data: {
//                     id: self.data("id")
//                 },
//                 method: "POST",
//                 success: function(data, textStatus, jqXHR) {
//                     if (data.message) {
//                         DFApp.notify(data.message, 'success')
//                     }
//                     self.hide();
//                     if (self.hasClass('js-add-feature-to-product')) {
//                         $('a.js-remove-feature-from-product', self.parent()).show();
//                     }
//                     if (self.hasClass('js-remove-feature-from-product')) {
//                         $('a.js-add-feature-to-product', self.parent()).show();
//                     }
//                 }
//             })
//         })
//     }
// }
