/* JS для работы со страницами ролей и прав доступа. */
// import UIkit from "uikit";

import Sortable from "sortablejs";

export function category_properties(body_tag) {
    $(".js-grouped-properties-lists").each(function(index, elem) {
        let group = $(this).data("group")
        let params = {}
        if (group) {
            Object.assign(params, {"group": group})
        }

        Sortable.create(
            elem, {
                handle: ".js-sortable-handle",
                onAdd: function(event) {
                    // Поменялась группа
                    let ul_item = $(event.to)
                    let li_item = $(event.item)

                    let preset_id = ul_item.data('preset-id');

                    if (ul_item.length > 0 && li_item.length > 0) {
                        li_item.find('input[type=hidden].js-property-preset-id').prop('name', 'presets[' + preset_id + ']');
                        li_item.find('input[type=checkbox].js-property-use-as-filter').prop('name', 'use_as_filter[' + preset_id + ']');
                        li_item.find('input[type=hidden].js-property-mark-to-remove1').prop('name', 'mark_to_remove[' + preset_id + ']');
                    }
                },
                ...params
            },

        )
    })

    // Пометка удаления
    // Просто переключаем соседний чекбокс
    $("a.js-mark-property-to-delete").on("click", function() {
        let check = $('input[type=checkbox]', $(this).parent());

        if (check.length > 0) {
            check.click()
            $(this).parents("div.row").each(function(idx, elem) {
                if (!$(elem).hasClass("text-danger")) {

                    $(elem).addClass("text-danger")
                } else {
                    $(elem).removeClass("text-danger")
                }
            })
        }
    })
}
