// import UIkit from "uikit";

(function ($) {

    let $container = null;
    let $select_image = null;
    let images_url = null;
    let on_add_instruction = null;

    $.fn.comics_widget = function (cmd, arg) {
        if (typeof cmd === 'object')
            return init(this, cmd);

        if (cmd == 'val') {
            if (arg == undefined)
                return get_val()
            set_val(arg)
        } else if (cmd == 'add_image') {
            add_image()
        } else if (cmd == 'add_instruction') {
            add_instruction(arg)
        }
    }

    function init(widget, options) {
        $container = $(widget)
        $select_image = options.$select_image;
        images_url = options.images_url;
        on_add_instruction = options.on_add_instruction
    }

    function set_val(val) {
        try {
            let parse = JSON.parse(val);
            $.each(parse, function (key, value) {
                if ('image' in value)
                    add_image(value['image']);
                else
                    add_instruction(value['instruction'])
            })
        } catch (ex) {
            console.error(ex)
            alert(ex)
        }
    }

    function get_val() {
        let value = [];
        $container.find('>div').each(function () {
            let $div = $(this);
            if ($div.data('holds') == 'image')
                value.push({'image': $div.data('image_id')})
            else {
                try {
                    var text = $div.data('value');
                } catch {
                    text = {}
                }
                value.push({'instruction': text})
            }
        })
        return JSON.stringify(value)
    }

    function add_image(image_id, image_url) {
        let $div = $('<div data-holds="image">').data('image_id', image_id).appendTo($container);
        let $border = $('<div class="df-image-thumbnail">').appendTo($div);
        let $left = $('<div class="left">left</div>').appendTo($border);
        let $close = $('<div class="close">close</div>').appendTo($border);
        let $img = $('<img>').appendTo($border)

        if (image_url)
            $img.attr('src', image_url);
        else if (image_id)
            $.get({
                url: '../comics',
                data: {image_id},
                success: function (data) {
                    $img.attr('src', data)
                }
            });

        $div.on('click', function () {
            $select_image.data('url', images_url)
            $select_image.data('delete_url', '../comics')
            $select_image.data('on_image_selected', function (image_id, image_src) {
                $div.data('image_id', image_id);
                $img.attr('src', image_src)
            })
            UIkit.modal($select_image).show('no');
        })

        $left.on('click', function () {
            move_left($div);
            return false;
        })

        $close.on('click', function () {
            $div.remove();
            return false;
        })
    }

    function add_instruction(value) {
        let $div = $('<div>').data('value', value).data('value', value).appendTo($container);
        let $border = $('<div class="df-image-thumbnail">T</div>').appendTo($div);
        let $left = $('<div class="left">left</div>').appendTo($border);
        let $close = $('<div class="close">close</div>').appendTo($border);

        $border.on('click', function () {
            on_add_instruction({
                data: $div.data('value') || {},
                title: '',
                on_submit(data) {
                    $div.data('value', data)
                }
            })
        })

        $left.on('click', function (e) {
            move_left($div);
            return false;
        })

        $close.on('click', function () {
            $div.remove();
            return false;
        })
    }

    function move_left($div) {
        let $prev = $div.prev()
        if ($prev.length)
            $div.detach().insertBefore($prev);
    }

}(jQuery));
