import jQuery from "jquery";

require("bootstrap");

import {Tooltip} from "bootstrap";
import Sortable from "sortablejs";

import {sortable} from "bootstrap-html5sortable"

import Inputmask from "inputmask";
import {Toast} from "bootstrap";

import "scrolltofixed/jquery-scrolltofixed-min";

import "./forks/jquery.stickytableheaders";
//import "./forks/dropuploader";

import "./widgets/jquery.checktree";
import "./widgets/modelfield";
import "./widgets/imageupload";
import "./widgets/translatable";
import "./widgets/fk_autocomplete";
import "./widgets/language_permission";
import "./widgets/datetime";
// import "./pdf/widget";
import "./widgets/uploader";

import "devbridge-autocomplete/dist/jquery.autocomplete.min";
import "spectrum-colorpicker/spectrum";
import "select2/dist/js/select2";
import "select2/dist/js/i18n/ru";
import "./filemanager";

import { rbac_main } from './rbac/rbac';
import { category_properties } from './categories/properties';
import { properties_presets } from "./properties/presets";
import { products_features } from "./products/features";
import { products_images } from "./products/images";
import { products_varieties } from "./products/varieties";
import { products_stickers } from "./products/stickers";
import { products_files } from "./products/files";
import { pofc } from "./pofc/pofc";
import { faqs } from "./faqs/faqs";
import { faqs_index } from "./faqs/index";
import { copy_to_form } from "./copy_to_form";
import { helpdesk_index } from "./helpdesk/index";
import { helpdesk_manage } from "./helpdesk/manage";
import { manuals_index } from "./manuals/index";
import { manuals_manage } from "./manuals/manage";
import { banners } from "./banners/banners";

import "./editor"
import "./fields"
import autosize from "autosize/dist/autosize";

import "./package/package";

$('document').ready(function(e) {
    $(".loader").fadeOut("slow");
});


$(function() {
    // Главная навигация
    const toggler = document.getElementById("mainNavigationToggler")
    const mainNavigation = document.getElementById("mainNavigation")

    mainNavigation.addEventListener("hide.bs.offcanvas", event => {
        $(toggler).addClass("collapsed")
    })
    mainNavigation.addEventListener("show.bs.offcanvas", event => {
        $(toggler).removeClass("collapsed")
    })

    // Глобальный поиск
    const globalSearchInput = $("#globalSearch")
    if (globalSearchInput) {
        globalSearchInput.autocomplete({
            serviceUrl: $(globalSearchInput).data("search-url"),
            preventBadQueries: false,
            groupBy: "group",
            width: "flex",
            deferRequestBy: 300,
            preserveInput: true,
            onSelect(suggestion) {
                if (suggestion.data.url) {
                    location.href = suggestion.data.url
                }
            }
        })
    }

    // Авторазмеры textarea
    autosize($("textarea.js-textarea-autosize"));

    // Всплывающие уведомления
    $("[data-df-flashed-message]").each(function(index, node) {
        new Toast(node).show()
    });

    // Тултипы
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl)=>  new Tooltip(tooltipTriggerEl))

    // Сортировка в таблицах
    $(".js-sortable-table").each(function(index, elem) {
        let group = $(this).data("group")
        let params = {}
        if (group) {
            Object.assign(params, {"group": group})
        }

        Sortable.create(
            elem, {
                handle: ".js-sortable-handle", ...params
            }
        )
    })

    $(document).find(".js-multiple-selector").each(function(index, elem) {
        $(elem).select2({
            theme: "bootstrap-5",
            closeOnSelect: false,
            language: $(document).lang
        })
    })

    $(".js-multiple-select-dropdown").select2({
        theme: "bootstrap-5"
    })

    function _submitFilterForm(elem) {
        $(elem).closest("form").submit()
    }

    // Фильтры в списках
    $("input.js-filter").on("keydown", function(e) {
        if (e.key === "Enter") {
            e.preventDefault()
            _submitFilterForm($(this))
        }
    })
    $("input.js-filter.js-date-select-input").on("change", function(e) { _submitFilterForm($(this) )})
    $("select.js-filter").on("change", function(e) {
        _submitFilterForm($(this))
    })

    // Групповая отметка
    $(":checkbox[data-group-selector]").each(function(idx, elem) {
        let self = $(elem)
        let _cls = self.data("items")

        function refresh(_cls) {
            let allItems = $(":checkbox." + _cls + ":checked")
            $("[data-grouped-button][data-items=" + _cls + "]").each(function(i, el) {
                if (allItems.length > 0) {
                    $(el).removeClass("disabled")
                } else {
                    $(el).addClass("disabled")
                }
            })
        }

        if (_cls) {
            $(":checkbox." + _cls).each(function(i, el) {
                $(el).on("click", function(e) {
                    refresh(_cls)
                })
            })

            self.on("change", function(e) {
                $(":checkbox." + _cls).each(function(i, el) {
                    $(el).prop("checked", self.is(":checked"))
                })
                refresh(_cls)
            })
        }
    })

    // Удаление строки в таблице
    $(document).on("click", "[data-df-remove-table-row]", function(e) {
        console.log("123")
        const tr = $(this).closest("tr")
        if (tr) {
            $(tr).remove()
        }
    })

    // Action button (например delete в рекламном блоке упаковки)
    $(document).on("click", "[data-df-action-delete-button]", function(e) {
        // Только для ссылок
        e.preventDefault()
        const self = $(this);
        const message = $(this).data("message") || false;
        const url = $(this).attr("href") || false;
        let accept = true
        if (message) {
            accept = confirm(message)
        }

        if (accept && url) {
            $.ajax({
                url: url,
                method: "DELETE",
                success: function(data, textStatus, jqXHR) {
                    console.log("SUCCESS", $(this))
                    console.log(self.parents("tr"))
                    self.parents("tr").remove()
                },
                error: function() {}
            })
        }
    })

    // Групповые кнопки
    $("[data-grouped-button]").each(function(idx, elem) {
        let self = $(elem)
        let _cls = self.data("items")
        let items = $(":checkbox." + _cls + ":checked")
        if (items.length) {
            self.removeClass("disabled")
        } else {
            self.addClass("disabled")
        }
    })

    // Сброс фильтров
    $("button[name=clear-filters]").on("click", function(e) {
        $("<input />").attr("type", "hidden").attr("name", $(this).attr("name")).appendTo(
            $(this).closest("form")
        )
        $(this).closest("form").submit()
    })

    // Загрузчики изображений
    $("input[type=file].js-ajax-image-uploader-multiple").each(function(inde, el) {
        const parentContainer = $(this).parent()
        const self = $(el)
        let uploadedList = $(parentContainer).children(".js-uploaded-images")

        $(el).fileinput({
            uploadUrl: $(el).data("url"),
            uploadAsync: true,
            deleteUrl: $(el).data("deleteurl"),
            browseOnZoneClick: true,

            dropZoneTitle: self.data("dropzonetitle"),
            dropZoneClickTitle: self.data("dropzoneclicktitle"),

            initialPreviewAsData: true,
            overwriteInitial: false,

            fileActionSettings: {
                showRemove: true,
                showUpload: false,
                showDownload: false,
                showDrag: false,
                showZoom: false,
                showRotate: false,
            },
            showClose: false,

            showBrowse: false,
            showCaption: false,
            showCancel: false,
            showRemove: false

        }).on("filebatchselected", function(event, files) {
            $(el).fileinput("upload")
        }).on("fileuploaded", function(event, data, previewIndex, index, fileId) {
            if (data.response && data.response.initialPreviewConfig) {
                data.response.initialPreviewConfig.forEach(function(valueObj, number) {
                    let key = valueObj.key

                    if (key) {
                        $("<input>").attr({
                            type: "hidden",
                            name: self.attr("name") + "_ids",
                            value: key,
                        }).appendTo(uploadedList)
                    }
                })
            }
            console.log('complete', data, previewIndex, index, fileId)
        }).on("filedeleted", function(event, key, jqXHR, data) {
            $(uploadedList).children("input[type=hidden][value=" + key + "]").remove()
            console.log("deleted", key)
        })
    });

    $("form#form-product-features").on("submit", function(e) {
        const formData = $(this).serializeArray()
        const form = $(this)
        const kRegex = /^(?<prefix>[A-Za-z0-9_]+)\-(?<index>[\d])-(?<fieldname>[A-Za-z0-9_]+)$/

        let newFormData = {}
        let indexMap = {}

        let i = 0
        $.each(formData, function(k, v) {
            const matches = v.name.match(kRegex)

            if (matches) {
                const element = $("#" + v.name);
                if (!indexMap.hasOwnProperty(matches[2])) {
                    indexMap[matches[2]] = i
                    i++;
                }  else {
                }

                let newName = matches[1] + "-" + indexMap[matches[2]] + "-" + matches[3];
                $(element).attr("name", newName)
                newFormData[newName] = v.value
            } else {
                newFormData[v.name] = v.value
            }
        })
        // e.preventDefault()
    })


    // Массовое добавление артикулов к файлу
    $("button.js-batch-load-sku").on("click", function() {
        const skus = $("#" + $(this).data("skus")).val();
        const addUrl = $(this).data("add-url");

        let tbody = $($(this).data('table_id') + ' > tbody')

        if (skus && addUrl) {
            $.ajax({
                method: "POST",
                url: addUrl,
                data: {
                    skus: skus
                },
                success(data, textStatus, jqXHR) {
                    if (jqXHR.status === 201) {
                        if (tbody && data.rows) {
                            data.rows.forEach(function(item) {
                                let elNewElem = $("<tr>" + item.row + "</tr>").prependTo(tbody)
                            })
                        }
                        let total = $("#total-linked-descriptions")
                        if (total && data.total) {
                            total.html(data.total)
                        }
                    }
                }
            })
        }
    })
});


$(function() {
    const df_admin = new DefenderAdmin();

    df_admin.bindFlashedMessages()
    df_admin.bindAutocomplete()
    df_admin.bindGroupedCheckboxes()
    df_admin.bindBottomBar()
    df_admin.bindStickyTable()
    df_admin.bindCheckTree()
    df_admin.bindColorInput()
    df_admin.bindTags()
    df_admin.bindDraftLink()
    df_admin.bindCheckTerritories()
    df_admin.bindSelectAll()

    df_admin.bindSingleImageUpload()
    df_admin.bindSVGUpload()
})

function DefenderAdmin() {
    this.body = $("body");
    // rbac_main(this.body);
    category_properties(this.body);
    // properties_presets(this.body);
    // products_features(this);
    // products_images(this);
    products_stickers(this);
    // products_varieties(this);
    // products_files(this);
    // pofc(this);
    // faqs(this);
    // faqs_index(this);
    copy_to_form();
    // helpdesk_index(this);
    // helpdesk_manage(this);
    // manuals_index(this);
    // manuals_manage(this);
    banners(this);
}

DefenderAdmin.prototype.notify = function(message, category) {
    new Toast($(this)).show()
    // UIkit.notification({
    //     message: message,
    //     status: category,
    //     pos: 'top-right'
    // })

}

DefenderAdmin.prototype.bindCheckTerritories = function() {
    $(".js-select-only-rus").on('click',function(e) {
        e.preventDefault()
        let ul_el = $('.uk-list .js-territories-list');

        $('input.js-territory-checkbox').each(function() {
            if (["RU", "BY", "UZ", "KZ", "142"].includes($(this).val())) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false)
            }
        })
    })
}

DefenderAdmin.prototype.bindFlashedMessages = function() {
    let glob = this;
    $("[data-df-flashed-message]").each(function(index, node) {
        new Toast(node).show()
        // let self = $(this)
        // glob.notify(self.data("message"), self.data("category"))
    });
}

DefenderAdmin.prototype.bindDraftLink = function() {
    $("[data-df-draft]").each(function(i, el) {
        let self = $(this)
        $(el).click(function() {
            // Сброс "человеческий перевод" если есть
            let is_g = $(el).data('clear-check')
            if (is_g !== undefined) {
                let fld = $("#" + is_g)
                if (fld) {
                    $(fld).prop("checked", false)
                }
            }

            let field = $(el).data('field')
            let val = $(el).data('value')
            let value = $(el).html()
            if (val !== undefined) {
                value = val
            }
            $('#' + field).val(value).focus()
        })
    })
}

DefenderAdmin.prototype.bindAutocomplete = function() {
    $("[data-df-autocompleteproduct_simple]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        $("body").on("click", "a.js-remove-from-list", function(e) {
            $(this)[0].closest("tr").remove()
            e.preventDefault();
        });


        let options = {
            serviceUrl: $this.data("url"),
            previewBadQueries: false,

            onSelect: function(val) {
                let tbody = $(self.data("table_id") + " > tbody")

                $('<tr><td>' + val.data +
                    '<input type="hidden" name="selproducts[]" value="' + val.id + '" /></td>' +
                    '<td><a target="_blank" href="' + val.product_url + '">' + val.title + '</a></td>' +
                    '<td><a href="#" class="js-remove-from-list uk-button uk-button-small uk-button-danger">Убрать</a></td>' +
                    '</tr>').prependTo(tbody);

                self.val('');
                self.focus();
            }
        }
        $(el).autocomplete(options)
    });

    $("#removeDescriptionGroupBtn").on("click", function(e) {
        if (!e.isDefaultPrevented()) {
            e.preventDefault()
            let rows = []
            let values = []


            $("input[type=checkbox].js-item-check:checked").each(function(index, element) {
                values.push($(element).val())
                rows.push($(element).parents("tr"))
            })

            $.ajax({
                method: "POST",
                url: $(this).attr("href"),
                data: {
                    ids: values
                },
                success(data, textStatus, jqXHR) {
                    $(rows).each(function(i, el) {
                        $(el).remove()
                    })
                    $(":checkbox[data-group-selector]").each(function(i, el) { $(el).prop("checked", false) })
                }
            })
        }
    })
    $("[data-df-featurelink]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,

            onSelect: function (val) {
                let tbody = $(self.data('table_id') + ' > tbody')

                $.ajax({
                    url: self.data("add-url"),
                    data: {
                        description_id: val.id,
                        header_id: val.id,
                        id: val.id,
                    },
                    method: "POST",
                    success: function (data, textStatus, jqXHR) {
                        if (jqXHR.status === 201) {
                            if (tbody && data.row) {
                                // $("<tr>" + data.row + "</tr>").appendTo(tbody)
                                let elNewElem = $("<tr>" + data.row + "</tr>").prependTo(tbody)

                                // Для списка особенностей
                                const switcher = $("#switcherShowHideDetails")
                                if (switcher) {
                                    let el = $(tbody).find(".js-long-features")
                                    if (switcher.attr("aria-expanded") === "true") {
                                        $(el).addClass("show")
                                    }
                                }

                                if (elNewElem) {
                                    const select = $(elNewElem).find(".js-multiple-selector")
                                    console.log(select)
                                    $(select).select2({
                                        theme: "bootstrap-5",
                                        closeOnSelect: false,
                                        language: $(document).lang
                                    })
                                }
                                // <---
                            }
                            let total = $("#total-linked-descriptions")
                            if (total && data.total) {
                                total.html(data.total)

                            }
                        }

                        self.val("");
                        self.focus();
                    }
                })
            }
        }
        $(el).autocomplete(options)

    });
    $("[data-df-autocompleteproduct]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,

            onSelect: function (val) {
                let tbody = $(self.data('table_id') + ' > tbody')

                $.ajax({
                    url: self.data("add-url"),
                    data: {
                        product_id: val.id,
                        ref_product_id: self.data("ref_product_id")
                    },
                    method: "POST",
                    success: function (data, textStatus, jqXHR) {
                        if (tbody) {
                            let adding = self.data('product_id') == val.id ? false : true;
                            if (adding) {
                                let rm_html = "<small class=\"uk-text-muted\">Убрать товар можно будет после обновления страницы</small>"
                                if (val.remove_url) {
                                    rm_html = '<button type="button" class="js-remove-file-assoc uk-button uk-button-danger"\n' +
                                        '                                    data-url="' + val.remove_url + '"' +
                                        '                                    >Убрать файл из товара</button>'
                                }
                                $('<tr><td>' + val.data + '</td>' +
                                    '<td><a href="' + val.product_url + '">' + val.title + '</a></td>' +
                                    '<td>' + rm_html + '</td>' +
                                    '</tr>').prependTo(tbody);
                            }
                            self.val('');
                            self.focus();
                        }
                    }
                })
            }
        }
        $(el).autocomplete(options)
    })
    $("[data-df-textautocomplete]").each(function(i, el) {
        let $this = $(this);

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,
            onSelect: function (val) {
                $target.each(function () {
                    $(this).val(val.data)
                })
                $target_value.each(function () {
                    $(this).val(val.value)
                })
            }
        }
        $(el).autocomplete(options)
    })
}

DefenderAdmin.prototype.bindStickyTable = function() {
    $("table[data-df-stickytableheader]").stickyTableHeaders({
        fixedOffset: $("#js-main-navbar")
    });
    $(window).trigger("resize.stickyTableHeaders");

    let resizeTimer = false;

    $(window).on("resize", function() {
        if (!resizeTimer) {
            $(window).trigger("resizestart");
        }
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            $(window).trigger("resizeend")
        }, 250);
    }).on("resizeend", function() {
        $(window).trigger('resize.stickyTableHeaders');
    })
}

DefenderAdmin.prototype.bindBottomBar = function() {
    $("[data-df-bottomtoolbar]").each(function(e, item) {
        $(item).scrollToFixed({
            bottom: 0,
            limit: 0,  // $(item).offset().top,
            zIndex: 999,
            className: 'uk-navbar-transparent'
        })
    });
}

DefenderAdmin.prototype.bindCheckTree = function() {
    $("ul[data-checktree]").checktree();
}

DefenderAdmin.prototype.bindGroupedCheckboxes = function() {
    // Программная иммитация радио-переключателей на основе класса js-group-checkbox и data-group-name
    $("input[type=checkbox][data-group-checkbox]").on('change', function() {
        let group = $(this).data('group-name')
        self = this
        if (this.checked) {
            $('input[type=checkbox][data-group-checkbox][data-group-name=' + group + ']').each(function() {
                if (this !== self) {
                    $(this).prop('checked', false);
                }
            })
        }
    })
}

DefenderAdmin.prototype.bindColorInput = function() {
    let default_attrs = {
        preferredFormat: "hex",
        showInput: true,
        allowEmpty: true
    }
    $("input[data-color]").each(function() {
        let self = $(this);

        default_attrs["color"] = self.attr("value")
        default_attrs["change"] = function(color) {
            if (color) {
                self.attr('value', color.toHexString());
            } else {
                self.attr('value', '');
            }
        }

        self.spectrum(default_attrs)
    })
}

DefenderAdmin.prototype.bindTags = function () {
    $("div.tags").each(function () {
        let $container = $(this);
        let $select = $container.find('select')

        function set_btn_state(btn, toggle) {
            let $btn = $(btn);
            let value = $btn.data('value');
            let $option = $select.find('option[value="' + value + '"');
            let state = $option.prop('selected')

            if(toggle) {
                state = !state;
                $option.prop('selected', state)
            }
            if(state) {
                $btn.addClass('uk-button-primary')
                $btn.removeClass('uk-button-default')
            } else {
                $btn.addClass('uk-button-default')
                $btn.removeClass('uk-button-primary')
            }
        }

        $container.find('div[data-value]').each(function () {
            set_btn_state(this, false);
        })

        $container.find('div[data-value]').on('click', function () {
            set_btn_state(this, true);
        })

    })
}

DefenderAdmin.prototype.bindSelectAll = function() {
    $(":checkbox[data-multiselect]").each(function(idx, elem) {
        let self = $(elem)
        let _cls = self.data('multiselect')
        if (_cls) {
            self.on("change", function(e) {
                $(":checkbox." + _cls).each(function(i, el) {
                    $(el).attr("checked", self.is(":checked"))
                })
            })
        }
    })
}

DefenderAdmin.prototype.bindSingleImageUpload = function() {
    $("input[type=file].js-uploader-single-image").each(function(ind, elem) {
        let self = $(elem);
        self.drop_uploader({
            accept: 'image/jpg,image/jpeg,image/png,image/webp',
            uploader_text: 'Перетащите сюда изображение для загрузки или',
            browse_text: 'выберете вручную',
            only_one_error_text: 'Можно загрузить только один файл',
            not_allowed_error_text: 'File type is not allowed',
            big_file_before_error_text: 'Files, bigger than',
            big_file_after_error_text: 'is not allowed',
            allowed_before_error_text: 'Only',
            allowed_after_error_text: 'files allowed',
            browse_css_class: 'button button-primary',
            browse_css_selector: 'file_browse',
            uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
            file_icon: '<i class="pe-7s-file"></i>',
            progress_color: '#4a90e2',
            time_show_errors: 5,
            layout: 'thumbnails',
            method: 'normal'
        });
    })
}


DefenderAdmin.prototype.bindSVGUpload = function() {
    const uploaders = $(".js-svg-upload")
    const previewArea = $(".js-svg-preview")

    uploaders.each(function(num, elem) {
        const textArea = $("#" + $(elem).data("preview-textarea"))
        $(elem).fileinput({
            uploadUrl: $(elem).data("url"),
            showUpload: false,
            showRemove: false,
            showCancel: false,
            showUploadedThumbs: false,
            showPreview: false,
            autoReplace: true,
            ajaxSettings: {
                type: 'html',
                method: 'POST',
                allow: '*.svg',
                beforeSend: function(environment) {
                    if (textArea) {
                        textArea.prop("disabled", true)
                    }
                },
                error: function () {
                    console.log("error", arguments)
                },
                completeAll: function(resp) {
                    console.log("complete", arguments, resp)
                    if (textArea) {
                        $(textArea).html(resp.responseText)
                        textArea.prop("disabled", false)
                    }
                    if (previewArea) {
                        $(previewArea).html(resp.responseText);
                    }
                }
            }
        }).on('filebatchselected', function(event, files) {
            $(this).fileinput("upload")
        })
    })
}


$(function() {
    Inputmask({"mask": "99.99.9999", "inputFormat": "dd.mm.yyyy"}).mask(".js-date-input")

    $(".js-preview-button").on("click", function (e) {
        // e.preventDefault()
        let sel = $("select[name=df-preview-links]").find(":selected").val()

        if (sel) {
            $(this).attr("href", sel)
        }
    })

    $("a.js-delete-confirmation-button").on("click", function(e) {
        const self = $(this)

        if (confirm(self.data("confirm"))) {
            return true
        } else {
            e.preventDefault()
            return false
        }
    })
})
