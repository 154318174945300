function delay(callback, ms) {
    let timer = 0;
    return function() {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            callback.apply(context, args)
        }, ms || 0);
    }
}

// Скрытие даталистов с вариантами автокомплита по клику вне области поля ввода +
$(function() {

    // Скрытие даталистов с вариантами автокомплита по клику вне области поля ввода
    $(document).on("click", function(e) {
        if ($(e.target).parents(".df-form__field--autocomplete").length === 0) {
            $(".df-form__field--autocomplete ul").addClass("d-none");
        }
    })

    // Автокомплит на поле ввода
    $(document).on("keyup", ".df-form__field--autocomplete input", delay(function() {
        const list$ = $(this).parent().siblings("ul");
        list$.removeClass("d-none");
        list$.addClass("loading");

        $.ajax({
            method: "GET",
            url: $(this).data("url"),
            data: {query: $(this).val(), reset: true},
            success: function (response) {
                console.log('123', response)
                if (response && response.suggestions && response.suggestions.length) {
                    let html = ""

                    response.suggestions.forEach((item) => {
                        html += `<li data-value="${item.id}">${item.value}</li>`;
                    })

                    // list$.find("li:not[data-id]").remove();
                    list$.find("li:not([data-value=''])").remove();
                    list$.append(html);
                    list$.removeClass("loading")
                } else {
                    list$.find("li:not([data-value=''])").remove();
                    list$.removeClass("loading");
                    // list$.addClass("d-none");
                }
            },
            error: function (xhr) {
                list$.find("li:not([data-value='')").remove();
                list$.removeClass("loading");
                list$.addClass("d-none");
            }
        })
    }, 500));

    // Выбор значения из результатов автокомплита +
    $(document).on(
        "click",
        ".df-form__field--autocomplete > ul > li",
        function () {
            const input$ = $(this)
                .parents(".df-form__field--autocomplete")
                .find("input");
            const hidden_input$ = $(this)
                .parents(".df-form__field--autocomplete")
                .find("input[type=hidden]")
            input$.attr("data-value", $(this).data("value"));
            if (input$.attr("data-value")) {
                input$.val($(this).text());
            } else {
                input$.val("")
            }
            hidden_input$.val($(this).data("value"));
            input$.siblings("ul").addClass("d-none");
        }
    )

    // Показ вариантов автокомплита на инпутах при клике +
    $(document).on("click", ".df-form__field--autocomplete input", function () {
        if (
            $(this).siblings("ul").hasClass("d-none") &&
            $(this).siblings("ul").children().length > 0
        ) {
            $(this).siblings("ul").removeClass("d-none");
        }
    })
});
