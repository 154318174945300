$(function() {
    $("button.js-select-language-permission").on("click", function(e) {
        const val = $(this).data("value")
        const parentContainer = $(this).parents(".js-locale-permissions-widget")

        parentContainer.find("select").each(function(i, elem) {
            $(elem).val(val)
        })
    });
})
