// import UIkit from "uikit";
import {Modal} from "bootstrap";

jQuery(function($) { $.extend({
    form: function(url, data, method) {
        if (method == null) method = "POST";
        if (data == null) data = {};

        let form = $("<form>").attr({
            method: method,
            action: url,
        }).css({
            display: "none"
        })

        let addData = function(name, data) {
            if ($.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    let value = data[i];
                    addData(name + "[]", value)
                }
            } else if (typeof data === "object") {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        addData(name + "[" + key + "]", data[key])
                    }
                }
            } else if (data != null) {
                form.append($("<input>").attr({
                    type: "hidden",
                    name: String(name),
                    value: String(data)
                }))
            }
        }

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                addData(key, data[key])
            }
        }

        return form.appendTo("body")
    }
})});


$(function() {
    const linksModal = document.getElementById("linksModal")
    if (linksModal) {
        linksModal.addEventListener("hide.bs.modal", function(event) {
            $(event.target).each(function(idx, elem) {
                const el = $(elem).find("#linksModalFormContainer")
                el.html("")
            })
        })
    }

    $(".df-file-link-popup").on("click", function (e) {
        e.preventDefault()
        e.target.blur()
        const self = $(this);

        $.ajax({
            url: self.attr("href"),
            success: function (response) {
                const linksModalContainer = $("#linksModalFormContainer")
                if (linksModalContainer) {
                    linksModalContainer.html(response)
                }
            }
        })
    });

    $(".df-file-move-to-popup").on("click", function(e) {
        e.preventDefault()
        e.target.blur()
        const self = $(this);
    })

    $(".js-delete-selected-files").on("click", function(e) {
        e.preventDefault()
        if (confirm('Выбранные файлы будут удалены?')) {
            const checked = $("input.js-file-row-checkbox:checked");

            if (checked) {
                let ids = []
                checked.each(function (i, elem) {
                    ids.push($(elem).val())
                });
                const form = $.form(
                    $(this).attr("href"),
                    {"file_id": ids},
                    "POST"
                )
                console.log(form)
                form.submit()
            }
        }
    });
});
