export function products_stickers(DFApp) {
    'use strict'

    // upload
    $("input[type=file].js-uploader-multiple-stickers").each(function(ind, elem) {
        $(elem).drop_uploader({
            uploader_text: 'Drop files to upload, or',
            browse_text: 'Browse',
            only_one_error_text: 'Only one file allowed',
            not_allowed_error_text: 'File type is not allowed',
            big_file_before_error_text: 'Files, bigger than',
            big_file_after_error_text: 'is not allowed',
            allowed_before_error_text: 'Only',
            allowed_after_error_text: 'files allowed',
            browse_css_class: 'button button-primary',
            browse_css_selector: 'file_browse',
            uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
            file_icon: '<i class="pe-7s-file"></i>',
            progress_color: '#4a90e2',
            time_show_errors: 5,
            layout: 'thumbnails',
            method: 'normal'
        });
    })
    // toggle sticker
    $("a.js-toggle-product-mark").on("click", function(e) {
        e.preventDefault()
        const checkBox = $(this).children("input[type=checkbox]")

        if (checkBox) {
            checkBox.prop("checked", !checkBox.attr("checked"))
            if ($(this).parent().hasClass("df-active-box")) {
                $(this).parent().addClass("df-inactive-box")
                $(this).parent().removeClass("df-active-box")
            } else {
                $(this).parent().addClass("df-active-box")
                $(this).parent().removeClass("df-inactive-box")
            }
        }
    })
    $('a.js-toggle-product-sticker').on('click', function(e) {
        e.preventDefault()
        let $t = $(this)
        const url = $(this).attr("href")

        if (url) {
            $.post({
                url: url,
                success: function(data) {
                    // if (data.message)
                    //     DFApp.notify(data.message, 'success')
                    if (data.active) {
                        $t.parent().addClass("df-active-box");
                        $t.parent().removeClass("df-inactive-box");
                    } else {
                        $t.parent().addClass("df-inactive-box");
                        $t.parent().removeClass("df-active-box");
                    }
                }
            })
        } else {
        }
    })

    $('button.js-delete-sticker').on("click", function(e) {
        e.stopPropagation();
        e.preventDefault();
        let self = $(this);
        if (confirm("Действительно хотите удалить стикер?")) {
            $.ajax({
                url: self.data("url"),
                data: {
                    "fileid": self.data("id")
                },
                method: "GET",
                success: function (data) {
                    location.reload()
                }
            })
        }
    })
}
