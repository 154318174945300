/*
Модальный диалог для переводов.
Одно верхнее окошко, из которого делается перевод в несколько нижних.
Установка и возвращаемое значение имеет вид словаря
{
en: 'hello'
ru: 'привет'
...
az: null  // еще не переведено
kz: '' // здесь не переводить
}

*/

// import UIkit from "uikit";

(function ($) {
    let state = null
    $.fn.l10n_modal = function (cmd, options) {
        if (cmd == 'init')
            state = init($(this), options)
        else if (cmd == 'show')
            state.show(options)
    }
}(jQuery));

function init($modal, options) {

    const locales = options.locales

    let ajax_seq = 0

    const $locale_from = $modal.find('.l10n_modal_locale_from')
    const $locales_to = $modal.find('.l10n_modal_locales_to')
    const $spinner = $modal.find('.l10n_modal_spinner')

    const state = {}

    // создать окошки для локалей
    let first = $($locales_to.children()[0])
    for (let i = 0; i < locales.length - 2; i++)
        first.clone().appendTo($locales_to)

    // установить тексты переводов для всех окошек виджета
    function set_l10n(val) {
        let text = val[get_locale($locale_from)];
        val_text($locale_from, text === undefined ? null : text)
        $locales_to.children().each(function () {
            let $to = $(this);
            let text = val[get_locale($to)];
            val_text($to, text === undefined ? null : text)
        })
    }

    // получить тексты переводов для всех окошек виджета
    function get_l10n() {
        let ret = {}
        ret[get_locale($locale_from)] = val_text($locale_from)
        $locales_to.children().each(function () {
            let $to = $(this);
            ret[get_locale($to)] = val_text($to)
        })
        return ret
    }

    function translate(locale_to) {
        ajax_seq++;
        let seq = ajax_seq;
        $spinner.removeClass('uk-hidden')
        $.post({
            url: '../ajax/translate',
            contentType: "application/json",
            data: JSON.stringify({
                locale_from: get_locale($locale_from),
                locale_to,
                data: get_l10n()
            }),
            success(data) {
                if (seq == ajax_seq)
                    set_l10n(data)
            },
            complete() {
                if (seq == ajax_seq)
                    $spinner.addClass('uk-hidden')
            }
        })
    }

    // очистить остальное. не трогаем галку "не переводить"
    $locale_from.find('.l10n_modal_clear_other').on('click', function () {
        $locales_to.children().each(function () {
            let $to = $(this);
            if (val_text($to) != '')
                val_text($to, null)
        })
        return false
    })

    // перевести остальное
    $locale_from.find('a.tr_other').on('click', function () {
        translate(null)
        return false
    })

    // скопировать. не трогаем "не переводить" и уже переведенные
    $locale_from.find('.l10n_modal_copy').on('click', function () {
        let text = val_text($locale_from)
        $locales_to.children().each(function () {
            let $to = $(this)
            if (val_text($to) == null)
                val_text($to, text)
        })
        return false
    })

    // не переводить остальное
    $locale_from.find('.l10n_modal_no_translate_other').on('click', function () {
        $locales_to.children().each(function () {
            let $to = $(this)
            if (val_text($to) == null)
                val_text($to, '')
        })
        return false
    })

    // убрать "не переводить остальное"
    $locale_from.find('.l10n_modal_clear_no_translate_other').on('click', function () {
        $locales_to.children().each(function () {
            let $to = $(this)
            if (val_text($to) == '')
                val_text($to, null)
        })
        return false
    })

    // не переводить это окошко
    $locale_from.find('.l10n_modal_no_translate').on('change',
        function () {
            val_text($locale_from, $(this).prop('checked') ? '' : null)
        })

    // присвоить всем окошкам языки
    function set_locales(locale_from) {
        let $all_to = $locales_to.children()
        let i = 0;
        $.each(locales, function () {
            if (this.val == locale_from)
                set_locale($locale_from, this.val, this.label)
            else {
                set_locale($($all_to[i]), this.val, this.label)
                i++
            }
        })
    }

    set_locales('ru')

    $locales_to.children().each(function () {

        let $to = $(this)

        // сделать эту локаль основным для перевода (в верхнее окошо)
        $to.find('.l10n_modal_set_locale_from').on('click', function () {
            let l10n = get_l10n()
            set_locales(get_locale($to))
            set_l10n(l10n)
            return false
        })

        // перевести это окошко
        $to.find('.l10n_modal_translate_this').on('click', function () {
            translate(get_locale($to))
            return false
        })

        // не переводить это окошко
        $to.find('.l10n_modal_no_translate').on('change',
            function () {
                val_text($to, $(this).prop('checked') ? '' : null)
            })
    })

    state.show = function (options) {
        ajax_seq++;
        $spinner.addClass('uk-hidden')

        if (options.title)
            $modal.find('h2').html(options.title)

        set_l10n(options.data)

        $modal.find('[href="#save"]').off('click').on('click', function () {
            UIkit.modal($modal).hide();
            options.on_submit(get_l10n())
            return false;
        })

        UIkit.modal($modal).show();
    }

    return state
}

// установить локаль для окошка $container
function set_locale($container, locale, label) {
    $container.find('.l10n_modal_locale_title').text(label).data('locale', locale)
}

// получить локаль для окошка $container
function get_locale($container) {
    return $container.find('.l10n_modal_locale_title').data('locale')
}

// получить/установить текст перевода для окошка $container
function val_text($container, val) {
    let $no_translate = $container.find('input');
    let $text = $container.find('textarea');

    if (val === undefined) {
        return $no_translate.prop('checked') ? '' : ($text.val() || null)
    } else {
        let no_translate = val == ''
        $no_translate.prop('checked', no_translate)
        $text.val(val).prop('disabled', no_translate)
    }
}
