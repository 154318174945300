
import { fileinput } from "bootstrap-fileinput"
import "bootstrap-fileinput/themes/bs5/theme.js";

$(function() {

    $(".js-drag-drop-multiple-image-upload").fileinput({
        showCaption: false,

        hideThumbnailContent: true,
        browseOnZoneClick: true,
        showUploadedThumbs: false,

        removeFromPreviewOnError: true,

        // showPreview: false,
        showBrowse: false,
        showCancel: false,
        showRemove: false,
        showClose: false,

        previewSettings: {
            image: {width: "100px"}
        },

        allowedFileTypes: ["image"]
    })

});
